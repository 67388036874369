<template>
  <BCard class="work_checklist_item mb-1 d-flex gap-2">
    <div class="flex-fill d-flex gap-1">
      <BFormCheckbox
        v-model="itemValue"
        disabled
      />
      <div>{{ item.name }}</div>
    </div>
    <div class="work_checklist_item-answer">
      <div
        class="text-muted"
      >
        <template
            v-if="item.answer_type === 'switch'"
        >
          <template v-if="item.answer === true || item.answer === false">
            {{ item.answer ? 'Да' : 'Нет' }}
          </template>
        </template>
        <template
            v-if="item.answer_type === 'radio' && item.answer !== null"
        >
          {{ radioValue(item) }}
        </template>
        <template
            v-if="item.answer_type === 'checkbox' && item.answer"
        >
          {{ checkboxValue(item) }}
        </template>
        <template
            v-if="item.answer_type === 'number' && item.answer"
        >
          {{ item.answer }}
        </template>
        <template
            v-if="item.answer_type === 'date' && item.answer"
        >
          {{ formatedDate(item.answer) }}
        </template>
        <template
            v-if="item.answer_type === 'text' && item.answer"
        >
          <template
              v-if="String(item.answer).length > 50"
          >
            <feather-icon
                style="cursor: pointer;"
                icon="MessageSquareIcon"
                size="18"
                @click="openText(item.answer)"
            />
          </template>
          <template
              v-else-if="String(item.answer).length <= 50"
          >
            {{ item.answer }}
          </template>
        </template>
        <template v-else-if="item.answer_type === 'file' && item.answer && item.answer.length > 0">
          <feather-icon
              style="cursor: pointer;"
              icon="ImageIcon"
              size="18"
              :badge="item.answer.length"
              @click="openImage(item.answer)"
          />
        </template>
      </div>
    </div>
    <div class="work_checklist_item-tooltip">
      <feather-icon
        v-if="item.description"
        :id="`workChecklistItemPopoverTarget-${item.id}`"
        icon="InfoIcon"
        :title="item.description"
      />
      <b-popover
        :target="`workChecklistItemPopoverTarget-${item.id}`"
        triggers="hover"
        placement="top"
      >
        <template #title>
          Описание
        </template>
        <div v-html="item.description" />
      </b-popover>
    </div>

    <b-modal
      ref="fileModalRef"
      v-model="fileModal"
      size="lg"
      title="Просмотр файлов"
  >
    <template #modal-footer="{}">
      <BButton @click="$refs['fileModalRef'].hide()">
        Закрыть
      </BButton>
    </template>
    <div class="fileModalImages">
      <b-carousel
          v-model="slide"
          controls
          label-next="''"
          label-prev="''"
          :interval="400000"
          style="width: 100%"
          indicators
      >
        <b-carousel-slide
            v-for="(file, index) in fileModalValue"
            :key="index"
            style="padding-bottom: 40px"
        >
          <template #img>
            <div class="d-flex flex-column align-items-center">
              <img
                  v-if="isImage(file.extension)"
                  :src="file.url"
                  style="width: 60%; max-height: 50vh"
              >
              <video
                  v-if="isVideo(file.extension)"
                  :src="file.url"
                  style="width: 60%; max-height: 50vh"
                  controls
              />
            </div>
          </template>
        </b-carousel-slide>
      </b-carousel>
    </div>
  </b-modal>

    <b-modal
        ref="textModalRef"
        v-model="textModal"
        size="lg"
        title="Просмотр текста"
    >
      <template #modal-footer="{}">
        <BButton @click="$refs['textModalRef'].hide()">
          Закрыть
        </BButton>
      </template>
      <div
          class="textModal"
          style="text-align: justify"
      >
        {{ textModalValue }}
      </div>
    </b-modal>
  </BCard>
</template>

<script>
import {computed, ref, toRefs} from '@vue/composition-api';
import { BCard, BButton, BModal, BCarousel, BCarouselSlide, BFormCheckbox, BPopover } from 'bootstrap-vue';
import fileTypeByExt from '@/utils/fileTypeByExt';
import moment from "moment";
import { formatDate } from "@/utils/time";

export default {
  components: {
    BCard,
    BFormCheckbox,
    BPopover,
    BModal,
    BCarousel,
    BCarouselSlide,
    BButton,
  },
  props: {
    item: Object,
  },
  setup(props) {
    const { item } = toRefs(props);
    const itemValue = computed(() => item.value.answer !== null);
    const slide = ref(0);
    const fileModalValue = ref(null);
    const fileModal = ref(false);
    const { getFileType } = fileTypeByExt();
    const openImage = (answer) => {
      fileModal.value = true;
      fileModalValue.value = answer;
    };

    const fileType = (ext) => getFileType(ext);
    const isImage = (ext) => fileType(ext) === 'image';
    const isVideo = (ext) => fileType(ext) === 'video';

    const textModalValue = ref(null);
    const textModal = ref(false);
    const openText = (answer) => {
      textModal.value = true;
      textModalValue.value = answer;
    };

    moment.locale('ru');
    const formatedDate = (value) => formatDate(value, {
      withYear: true,
      withHours: true,
      withMinutes: true,
    });

    const checkboxValue = (element) => {
      if (!element.answer || element.answer.length === 0) {
        return '';
      }

      const answersArray = [];
      element.answer.forEach((el) => {
        // eslint-disable-next-line radix
        answersArray.push(element.answer_options[parseInt(el)]);
      });

      return answersArray.join(', ');
    };

    const radioValue = (element) => {
      if (!element.answer_options || element.answer_options.length === 0
          || element.answer === null) {
        return '';
      }
      // eslint-disable-next-line radix,implicit-arrow-linebreak
      return element.answer_options[parseInt(element.answer)];
    };

    return {
      itemValue,
      openImage,
      isImage,
      isVideo,
      openText,
      fileModalValue,
      textModalValue,
      textModal,
      fileModal,
      slide,
      formatedDate,
      checkboxValue,
      radioValue,
    };
  },
};
</script>

<style lang="sass">
.work_checklist_item
  border: 1px solid rgba(34, 41, 47, 0.125)
  .card-body
    padding: 14px
    display: flex
    row-gap: 8px
    column-gap: 8px
  &-tooltip
    width: 20px
.fileModalImages
  display: flex
  flex-direction: column
  gap: 12px
  & img
    border-radius: 4px
.carousel-indicators
  & li
    background-color: rgb(115, 103, 240)
.carousel-control-prev-icon
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%237367F0' viewBox='0 0 8 8'%3E%3Cpath d='M5.25 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z'/%3E%3C/svg%3E") !important
.carousel-control-next-icon
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%237367F0' viewBox='0 0 8 8'%3E%3Cpath d='M2.75 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z'/%3E%3C/svg%3E") !important
</style>
