<template>
  <BCard v-if="item.checklists_ids.length">
      <h4
          :class="{'mb-3' : !isMobile}"
      >
        Работы
      </h4>

    <div>
      <WorkChecklist
        v-for="checklist in item.checklists"
        :key="checklist.id"
        :is-request-status-completed="isRequestStatusCompleted"
        :item="checklist"
        @openChecklist="$emit('openChecklist', $event)"
      />
    </div>

    <div>
      <BButton
          v-if="item.checklists
          && !isRequestStatusCompleted
          && $store.getters['user/permission']('requests:update')"
          variant="primary"
          @click="$emit('openChecklist', item.checklists[0])"
      >
        Начать работу
      </BButton>
    </div>
  </BCard>
</template>

<script>
import { BCard, BButton } from 'bootstrap-vue';
import { ref } from "@vue/composition-api";
import { useStore } from "@/hooks/useStore";
import WorkChecklist from './WorkChecklist.vue';

export default {
  components: {
    WorkChecklist,
    BCard,
    BButton,
  },
  props: {
    item: Object,
    isRequestStatusCompleted: Boolean,
  },
  setup() {
    const isMobile = ref(false);
    const store = useStore();

    isMobile.value = store.state.app.isMobile;

    return {
      isMobile,
    };
  },
};
</script>
