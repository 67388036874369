<template>
  <div class="mb-3">
    <h5>{{ item.name }}</h5>
    <div class="mb-1">
      {{ item.description }}
    </div>

    <div class="mb-2">
      <b-progress
        :value="progress"
        :max="progressMax"
        :variant="item.finished ? 'success' : 'primary'"
        height="8px"
      />
    </div>

    <div v-if="item.checklist_items.length">
      <div class="font-weight-bold mb-50">
        Пункты чек-листа
      </div>
      <WorkChecklistItem
        v-for="checklistItem in item.checklist_items"
        :key="checklistItem.id"
        :item="checklistItem"
      />

<!--      <BButton-->
<!--        v-if="!item.finished-->
<!--        && !isRequestStatusCompleted-->
<!--        && $store.getters['user/permission']('requests:update')"-->
<!--        variant="primary"-->
<!--        @click="$emit('openChecklist', item)"-->
<!--      >-->
<!--        Начать работу-->
<!--      </BButton>-->
    </div>
  </div>
</template>

<script>
import {
  BProgress,
  // BButton
} from 'bootstrap-vue';
import { computed, toRefs } from '@vue/composition-api';
import WorkChecklistItem from './WorkChecklistItem.vue';

export default {
  components: {
    BProgress,
    // BButton,
    WorkChecklistItem,
  },
  props: {
    item: Object,
    isRequestStatusCompleted: Boolean
  },
  setup(props) {
    const { item } = toRefs(props);
    // eslint-disable-next-line max-len
    const progress = computed(() => item.value.checklist_items?.filter((i) => i.answer !== null).length);
    const progressMax = computed(() => item.value.checklist_items?.length);

    return {
      progress,
      progressMax,
    };
  },
};
</script>
